<template>
  <div class="footer__bg">
    <div class="footer">
      <img alt="Логотип" class="footer__logo" src="../assets/images/лого_футера.png">
      <div class="footer__content">
        <h2 class="footer__title">СТИПЕНДИИ ДЛЯ СТУДЕНТОВ И АСПИРАНТОВ</h2>
        <router-link v-for="item in footerGrantNav" :key="item.path" :to="item.path" class="footerGrantNav__text">
          {{ item.text }}
        </router-link>
        <div class="footerNav__text">
          <div v-for="item in footerNav" :key="item.path" class="footerLink">
            <router-link :to="item.path">
              {{ item.text }}
            </router-link>
            <span>•</span>
          </div>
        </div>
        <div class="footer__signature">
          <img alt="Логотип" class="footer__signature-logo" src="../assets/images/лого_подписи.svg">
          <div class="footer__signature-text">
            <p class="footer__signature-header">&#169; ФГБУ &laquo;Интеробразование&raquo;, 2021 - {{ currentYear }}</p>
            <p>При цитировании ссылка на первоисточник обязательна</p>
            <p>website-support@ined.ru (техническая поддержка сайта)</p>
          </div>
        </div>
        <div class="footer__social">
          <a class="footer__social-facebook" href="https://vk.com/stipendiat_russia" @mouseout="facebookHover = false"
             @mouseover="facebookHover = true">
            <img v-if="!facebookHover" alt="vk" src="@/assets/icons/vk(white).svg" style="">
            <img v-if="facebookHover" alt="vk" src="@/assets/icons/vk(white)-hover.svg" style="">
          </a>
          <a class="footer__social-youtube" href="https://t.me/stipendiatrussia" @mouseout="youtubeHover = false"
             @mouseover="youtubeHover = true">
            <img v-if="!youtubeHover" alt="tg" src="@/assets/icons/tg(white).svg">
            <img v-if="youtubeHover" alt="tg" src="@/assets/icons/tg(white)-hover.svg">
          </a>
          <!--          <a class="footer__social-instagram" href="https://www.instagram.com/stipendiat_russia/" @mouseout="instagramHover = false" @mouseover="instagramHover = true">-->
          <!--            <img v-if="!instagramHover" alt="instagram" src="@/assets/icons/instagram(white).svg">-->
          <!--            <img v-if="instagramHover" alt="instagram" src="@/assets/icons/instagram-hover40px.svg">-->
          <!--          </a>-->
          <!--          <a class="footer__social-facebook" href="https://www.facebook.com/groups/stipendiat.russia" @mouseout="facebookHover2 = false" @mouseover="facebookHover2 = true">-->
          <!--            <img v-if="!facebookHover2" alt="Facebook" src="@/assets/icons/Facebook(white).svg" style="">-->
          <!--            <img v-if="facebookHover2" alt="Facebook" src="@/assets/icons/Facebook(white)-hover.svg" style="">-->
          <!--          </a>-->
        </div>
      </div>
    </div>
    <div class="footer footer--mobile">
      <div class="footer__line">
        <img alt="Логотип" class="footer__logo" src="../assets/images/лого_футера.png">
        <h2 class="footer__title">СТИПЕНДИИ <br> ДЛЯ СТУДЕНТОВ <br> И АСПИРАНТОВ</h2>
      </div>
      <div class="footer__signature">
        <img alt="Логотип" class="footer__signature-logo" src="../assets/images/лого_подписи.svg">
        <div class="footer__signature-text">
          <p class="footer__signature-header">&#169; ФГБУ &laquo;Интеробразование&raquo;, 2021 - {{ currentYear }}</p>
          <p class="footer__signature-text2">При цитировании ссылка на первоисточник обязательна</p>
          <p class="footer__signature-text2">website-support@ined.ru (техническая поддержка сайта)</p>
        </div>
      </div>
    </div>
    <div class="sputnik-container">
      <div id="sputnik-informer"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      domen: window.location.origin,
      host: window.location.hostname,
      facebookHover: false,
      facebookHover2: false,
      youtubeHover: false,
      instagramHover: false,
      footerGrantNav: [
        {
          path: '/presidentandthegovernment',
          text: 'Стипендии Президента и Правительства Российской Федерации',
          order: '0',
          visible: true
        },
        {
          path: '/personal',
          text: 'Именные стипендии',
          order: '1',
          visible: true
        },
        {
          path: '/forstudyingabroad',
          text: 'Зарубежные стипендии',
          order: '2',
          visible: true
        }
      ],
      footerNav: [
        {
          path: '/statistics',
          text: 'Статистика',
          order: '0',
          visible: true
        },
        {
          path: '/faq',
          text: 'Часто задаваемые вопросы',
          order: '1',
          visible: true
        },
        {
          path: '/students_stories',
          text: 'Истории успеха',
          order: '2',
          visible: true
        },
        {
          path: '/news',
          text: 'Новости',
          order: '3',
          visible: true
        },
        {
          path: '/contacts',
          text: 'Контакты',
          order: '4',
          visible: true
        },
      ],
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/utils/variables.sass";

.sputnik-container {
  width: 100%;
  max-width: 1160px;
  position: relative;
  margin: 0 auto;

  @media screen and (max-width: $mobile-size){
    bottom: 20px;
    right: calc(50% - 44px);
  }
}

#sputnik-informer {
  position: absolute;
  bottom: 150px;
  right: 0;

  @media screen and (max-width: $mobile-size){
    bottom: -40px;
    //right: ;
  }
}

</style>