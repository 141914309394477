<template>
  <div class="header">
    <a class="header__logo" href="/">
      <img
        alt="Логотип сайта"
        src="../assets/images/logo_header.svg"
      >
    </a>
    <div class="header__social-container2">
        <a class="header__social width__icon header__icon1" href="https://vk.com/stipendiat_russia" >
          <img class="header__icon1" alt="VK" src="@/assets/icons/VK(blue).svg" style="">
        </a>
        <a class="header__social width__icon" href="https://t.me/stipendiatrussia">
          <img alt="Tg" src="@/assets/icons/Tg(blue).svg">
        </a>
<!--        <a class="header__social width__icon" href="https://www.instagram.com/stipendiat_russia/">-->
<!--          <img alt="instagram" src="@/assets/icons/instagram(blue).svg">-->
<!--        </a>-->
<!--         <a class="header__social width__icon" href=https://www.facebook.com/groups/stipendiat.russia>-->
<!--          <img alt="Facebook" src="@/assets/icons/Facebook(blue).svg" style="">-->
<!--        </a>-->
      </div>
    <div class="header__main-container">
      <div class="header__social-container">
        <a class="header__social" href="https://vk.com/stipendiat_russia" @mouseout="facebookHover = false" @mouseover="facebookHover = true">
          <img v-if="!facebookHover" alt="vk" src="@/assets/icons/vk.svg" style="">
          <img v-if="facebookHover" alt="vk" src="@/assets/icons/vk-hover.svg" style="">
        </a>
        <a class="header__social" href="https://t.me/stipendiatrussia" @mouseout="youtubeHover = false" @mouseover="youtubeHover = true">
          <img v-if="!youtubeHover" alt="tg" src="@/assets/icons/tg.svg">
          <img v-if="youtubeHover" alt="tg" src="@/assets/icons/tg-hover.svg">
        </a>
<!--        <a class="header__social" href="https://www.instagram.com/stipendiat_russia/" @mouseout="instagramHover = false" @mouseover="instagramHover = true">-->
<!--          <img v-if="!instagramHover" alt="instagram" src="@/assets/icons/instagram.svg">-->
<!--          <img v-if="instagramHover" alt="instagram" src="@/assets/icons/instagram-hover.svg">-->
<!--        </a>-->
<!--         <a class="header__social" href=https://www.facebook.com/groups/stipendiat.russia @mouseout="facebookHover1 = false" @mouseover="facebookHover1 = true">-->
<!--          <img v-if="!facebookHover1" alt="vk" src="@/assets/icons/Facebook.svg" style="">-->
<!--          <img v-if="facebookHover1" alt="vk" src="@/assets/icons/Facebook-hover.svg" style="">-->
<!--        </a>-->
      </div>
      <div class="header__language-container" style="display: none">
        <a class="header__lang">RU</a>
        <a class="header__lang">EN</a>
      </div>
      <a class="header__archive-container" href="/archive" @mouseout="archiveHover = false"
         @mouseover="archiveHover = true">
        <img v-if="!archiveHover" alt="Архив" class="header__archive-image" src="@/assets/icons/archive.svg">
        <img v-if="archiveHover" alt="Архив" class="header__archive-image" src="@/assets/icons/archive-hover.svg">
        <p class="header__archive-text">Документы</p>
      </a>
      <a class="header__lk-container" href="https://stp.ined.ru/" @mouseout="lkHover = false" @mouseover="lkHover = true">
        <img v-if="!lkHover" alt="Личный кабинет" class="header__lk-image" src="@/assets/icons/lk.svg">
        <img v-if="lkHover" alt="Личный кабинет" class="header__lk-image" src="@/assets/icons/lk-hover.svg">
        <p class="header__lk-text">Рабочий кабинет организации</p>
      </a>
      <div @click="openHelp()" @mouseout="eyeHover = false" @mouseover="eyeHover = true">
        <img v-if="!eyeHover" alt="Глаз" class="header__eye" src="@/assets/icons/eye.svg">
        <img v-if="eyeHover" alt="Глаз" class="header__eye" src="@/assets/icons/eye-hover.svg">
      </div>
      <div class="line"></div>
      <div class="header__menu">
        <a class="header__menu-item" href="/vse_stp" style="color: #09557B" @mouseenter="grantsLinkHover = true">Стипендии</a>
<!--        <a class="header__menu-item" href="/students_stories" style="color: #1981B5">Истории успеха</a>-->
        <a class="header__menu-item" href="/media" style="color: #1E8DC4">Истории успеха</a>
        <a class="header__menu-item" href="/news" style="color: #2E94C8">Новости</a>
        <a class="header__menu-item" href="/contacts" style="color: #26A4E2">Контакты</a>
      </div>
    </div>
    <div v-if="grantsLinkHover" class="grantsLink" @mouseleave="grantsLinkHover = false">
      <h3 class="grantsLink__header">Стипендии</h3>
      <div class="grantsLink__line"></div>
      <div class="grantsLink-container">
        <a href="/presidentandthegovernment">Стипендии Президента и Правительства</a>
        <a href="/personal">Именные стипендии</a>
        <a href="/forstudyingabroad">Зарубежные стипендии</a>
        <a href="/personal2">Именные стипендии для коренных <br/>малочисленных народов Севера, <br/>Сибири и Дальнего Востока</a>
        <a href="/st_imen">Именные стипендии субъектов РФ</a>
        <a href="/st_foiv">Именные стипендии ФОИВ и ООВО</a>
        <a href="/stipendials">Стипендиальные программы</a>
      </div>
<!--      <div class="grantsLink-container_bottom">-->
<!--        <img alt="" src="@/assets/icons/statistic.svg">-->
<!--        <a href="/statistics">Статистика</a>-->
<!--      </div>-->
      <div class="grantsLink-container_bottom">
        <img alt="" src="@/assets/icons/lamp.svg">
        <a href="/faq">Часто задаваемые вопросы</a>
      </div>
    </div>
    <div class="header__burger" @click="openBurger=true">
      <img src="@/assets/icons/burger.svg">
    </div>
    <div class="burger" v-if="openBurger===true">
      <div>
      <div class="burger__img">
        <a href="/">
          <img src="@/assets/icons/logo-stipendiat.svg">
        </a>
        <img class="burger-img1" src="@/assets/icons/circle.svg" @click="openBurger=false">
      </div>
        <div class="list">
          <div>
            <div><a class="list" href="/vse_stp" >Стипендии</a></div>
<!--            <div><a class="list" href="/students_stories">Истории успеха</a></div>-->
            <div><a class="list" href="/news" >Новости</a></div>
            <div><a class="list" href="/media" >Истории успеха</a></div>
          </div>
          <div class="list2">
            <div><a class="list" href="/statistics" >Статистика</a></div>
            <div><a class="list" href="/archive">Документы</a></div>
            <div><a class="list" href="/faq">ЧаВо</a></div>
            <div><a class="list" href="/contacts">Контакты</a></div>
          </div>
        </div>
        <a href="https://stp.ined.ru/">
          <button class="burger__button" href="https://stp.ined.ru/">
              <img src="@/assets/icons/personal-cabinet.svg">
              Личный кабинет
            </button>
        </a>
          <div class="social-networks">
            <a href="https://vk.com/stipendiat_russia">
              <img  src="@/assets/icons/vk(white).svg" style="">
            </a>
            <a href="https://t.me/stipendiatrussia" >
              <img src="@/assets/icons/tg(white).svg" style="">
            </a>
<!--            <a href="https://www.instagram.com/stipendiat_russia/">-->
<!--              <img src="@/assets/icons/instagram(white).svg" style="">-->
<!--            </a>-->
<!--            <a href="https://www.facebook.com/groups/stipendiat.russia">-->
<!--              <img src="@/assets/icons/Facebook(white).svg" style="">-->
<!--            </a>-->
          </div>
        </div>
            <div class="icons">
              <div>
              <img src="@/assets/icons/RU.svg" >
            <img src="@/assets/icons/EN.svg">
          </div>
        <img src="@/assets/icons/eye(white).svg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {
      openBurger: false,
      facebookHover: false,
      facebookHover1: false,
      youtubeHover: false,
      instagramHover: false,
      eyeHover: false,
      archiveHover: false,
      lkHover: false,
      grantsLinkHover: false,
    }
  },
  methods: {
    openHelp() {
      document.querySelector('#specialButton').click()
    }
  }
}
</script>

<style scoped>

</style>
